import React from "react";
import { useState, useEffect } from "react";
import FamilyDashboard from "../../Family/Dashboard/Dashboard";
import PlusButton from "../../PlusButton/PlusButton";
import "./MessageChat.css";

const MessageChat = () => {
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 575) {
        // Adjust the breakpoint as needed
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = ""; // Reset for mobile
      }
    };

    handleResize(); // Initial check

    window.addEventListener("resize", handleResize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.style.overflowY = ""; // Cleanup on unmount
    };
  }, []);
  return (
    <>
      <FamilyDashboard />
      <div className="main-content message-chat-box">
        <div className="container-fluid p-0 m-0">
          <div className="home">
            <div className="meassge-start">
              <div className="row g-0">
                <div className="col-sm-5 col-md-5 col-lg-5 col-xl-4">
                  <div className="conversations-box position-relative">
                    <div className="message-left-header justify-content-between align-items-center">
                      <h1 className="mb-2 mb-md-0">Conversations</h1>
                      <PlusButton plusBtnName="New Message" />
                    </div>
                    <div className="search-bar">
                      <input type="text" placeholder="Search Message" />
                      <img src="/images/search-icon.png" alt="search-icon" />
                    </div>
                    <div className="multiple-chat">
                      <div className="chat-user active">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Ashok Kumar</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat-user">
                        <div className="chat-user-left d-flex align-items-strat justify-content-between">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/images/Critical-Alerts-profile.svg"
                              alt="user-icon"
                            />
                            <div>
                              <h3>Vishal Anand</h3>
                              <p>Not able to walk properly since morning</p>
                            </div>
                          </div>
                          <div className="time-chat">
                            <h6>11:59 AM</h6>
                            <p>2</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7 col-md-7 col-lg-7 col-xl-8 d-none d-sm-block">
                  <div className="chat-start">
                    <div className="chat-start-header d-flex justify-content-between align-items-center">
                      <div className="d-flex gap-2 align-items-center">
                        <img
                          src="/images/Critical-Alerts-profile.svg"
                          alt="user-icon"
                        />
                        <div>
                          <h3>Ashok Kumar</h3>
                          <span className="d-flex">Active</span>
                        </div>
                      </div>
                      <img
                        className="more-icon"
                        src="/images/more.png"
                        alt="more-icon"
                      />
                    </div>
                    <div className="main-chat-box">
                      <div className="msg-box">
                        <div className="d-flex gap-2">
                          <img
                            src="/images/Critical-Alerts-profile.svg"
                            alt="user-icon"
                          />
                          <div>
                            <p>Hello There</p>
                            <p>Not able to walk properly since morning</p>
                            <span>2 mins ago</span>
                          </div>
                        </div>
                      </div>
                      <div className="msg-box">
                        <div className="d-flex gap-2">
                          <img
                            src="/images/Critical-Alerts-profile.svg"
                            alt="user-icon"
                          />
                          <div>
                            <p>Hello There</p>
                            <p>Not able to walk properly since morning</p>
                            <span>2 mins ago</span>
                          </div>
                        </div>
                      </div>
                      <div className="msg-box">
                        <div className="d-flex gap-2">
                          <img
                            src="/images/Critical-Alerts-profile.svg"
                            alt="user-icon"
                          />
                          <div>
                            <p>Hello There</p>
                            <p>Not able to walk properly since morning</p>
                            <span>2 mins ago</span>
                          </div>
                        </div>
                      </div>
                      <div className="msg-box-reply">
                        <div className="d-flex gap-2">
                          <div className="d-flex align-items-end flex-column">
                            <p>Hello There</p>
                            <p>Not able to walk properly since morning</p>
                            <span>2 mins ago</span>
                          </div>
                          <img
                            src="/images/Critical-Alerts-profile.svg"
                            alt="user-icon"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="chat-footer">
                      <div className="chat-footer-write">
                        <input
                          type="text"
                          placeholder="Type something here..."
                        />
                        <div className="d-flex gap-2 align-items-center gap-sm-3">
                            <button><img src="/images/gallery.svg" alt="gallery-upload"/></button>
                            <button><img src="/images/attech-link.svg" alt="gallery-upload"/></button>
                            <button className="btn-send-msg"><img src="/images/message-send.svg" alt="gallery-upload"/></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MessageChat;
