import React from "react";

import "./dashboard.css";
import { useNavigate, Link } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <div className="dashboard">
        <div className="dashboard-two">
          <div className="top-bar">
            <div className="top-bar-inner">
              <div className="mobile-menu d-lg-none">
                <div className="aside-bar">
                  <div className="aside-bar-menu">
                    <nav className="navbar navbar-expand-lg">
                      <div className="">
                        <button
                          className="navbar-toggler"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <i className="fa-light fa-bars"></i>
                        </button>

                        <div
                          className="collapse navbar-collapse"
                          id="navbarSupportedContent"
                        >
                          <div className="logo">
                            <a className="navbar-brand" href="#">
                              <img
                                src="/images/endwelllcare-logo.png"
                                alt="Logo"
                              />
                            </a>
                          </div>
                          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item active">
                              <a
                                className="nav-link active"
                                aria-current="page"
                                href="#"
                              >
                                <img
                                  src="/images/home-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img
                                  src="/images/home.svg"
                                  alt=""
                                  className="color-icon"
                                />
                                Home
                              </a>
                            </li>
                            <li className="nav-item active">
                              <Link to="/admin/registration">
                                <a
                                  className="nav-link active"
                                  aria-current="page"
                                  href="#"
                                >
                                  <img
                                    src="/images/home-2.svg"
                                    alt=""
                                    className="white-icon"
                                  />
                                  <img
                                    src="/images/home.svg"
                                    alt=""
                                    className="color-icon"
                                  />
                                  New Registration
                                </a>
                              </Link>
                            </li>
                            <li className="nav-item ">
                              <a className="nav-link" href="#">
                                <img
                                  src="/images/health-monitoring-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span> Health Monitoring</span>
                              </a>
                            </li>
                            <li className="nav-item ">
                              <a className="nav-link d-flex gap-1" href="#">
                                <div>
                                  <img
                                    src="/images/settings-gear-2.svg"
                                    alt=""
                                    className="white-icon"
                                  />
                                  <img src="" alt="" className="color-icon" />
                                </div>
                                <span className="d-block">
                                  Medication Management
                                </span>
                              </a>
                            </li>
                            <li className="nav-item ">
                              <a className="nav-link" href="#">
                                <img
                                  src="/images/page-search-lines-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span> Activity tracker</span>
                              </a>
                            </li>
                            <li className="nav-item ">
                              <a className="nav-link" href="#">
                                <img
                                  src="/images/file-text-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span>Incident Reports</span>
                              </a>
                            </li>
                            <li className="nav-item ">
                              <a className="nav-link" href="#">
                                <img
                                  src="/images/book-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span>Communication Log</span>
                              </a>
                            </li>
                            <li className="nav-item ">
                              <a className="nav-link" href="#">
                                <img
                                  src="/images/key-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span>Care Plan Access</span>
                              </a>
                            </li>
                            <li className="nav-item ">
                              <a className="nav-link" href="#">
                                <img
                                  src="/images/trending-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span> Reports & Analytics</span>
                              </a>
                            </li>
                            <li className="nav-item ">
                              <a className="nav-link" href="#">
                                <img
                                  src="/images/receipt-bill-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span> Billing</span>
                              </a>
                            </li>
                            <li className="nav-item logout-button">
                              <a className="nav-link" href="#">
                                <img
                                  src="/images/logout.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span> Logout</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="dummy-nav me-3">
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                  <div className="container-fluid">
                    <div
                      className="collapse navbar-collapse"
                      id="navbarNavDropdown"
                    >
                      <ul className="navbar-nav">
                        <li className="nav-item dropdown px-2">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            All Links
                          </a>
                          <ul className="dropdown-menu">
                            <div className="mygird-ul">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/registration"
                                >
                                  new registration
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/carehome"
                                >
                                  carehome
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/patient-list"
                                >
                                  patient list
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/patient-health"
                                >
                                  patient health
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/medication-management"
                                >
                                  medication management
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/activity-tracker"
                                >
                                  activity tracker
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/diet-nutrition"
                                >
                                  diet nutrition
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/health-monitoring"
                                >
                                  health monitoring
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/doctor-medication-management"
                                >
                                  doctor medication management
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/messagechat"
                                >
                                  messagechat
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/nurse/resident-health-data"
                                >
                                  nurse resident healthdata
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/nurse/resident-health-data-view"
                                >
                                  nurse resident healthdataview
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/nurse/notification"
                                >
                                  nurse notification
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/nurse/medications"
                                >
                                  nurse medications
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/medication"
                                >
                                  medication
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/staff-details"
                                >
                                  staff-details
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/invoice">
                                  invoice
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/doctor">
                                  doctor
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/home"
                                >
                                  family home
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/login"
                                >
                                  family login
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/signup"
                                >
                                  family signup
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/otp"
                                >
                                  family otp
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/forgot-password"
                                >
                                  forgot-password
                                </Link>
                              </li>
                            </div>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="top-bar-search">
                <div className="sarch-bar position-relative">
                  <input type="search" placeholder="Search " />
                  <img src="/images/search-normal.png" />
                </div>
                <div className="bell-icon">
                  <i className="fa-regular fa-bell"></i>
                  <span></span>
                </div>
                <div className="login-profile">
                  <div className="Profile-image">
                    <img src="/images/profile-image.jpeg" alt="" />
                  </div>
                  <div className="profile-details" onClick={() => logout()}>
                    <p>Ashok</p>
                    <span>Admin</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tab-menu">
        <div className="aside-bar">
          <div className="aside-bar-menu">
            <nav className="navbar navbar-expand-lg">
              <div className="">
                <div className="logo">
                  <a className="navbar-brand" href="#">
                    <img src="/images/endwelllcare-logo.png" alt="" />
                  </a>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li
                      className={`nav-item ${
                        window.location.pathname === "/admin/carehome"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/admin/carehome">
                        <a
                          className="nav-link active"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/home-2.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/home.svg"
                            alt=""
                            className="color-icon"
                          />
                          Home
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "/admin/registration"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/admin/registration">
                        <a
                          className="nav-link active"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/registration.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/registration-active.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span> New Registration</span>
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "/admin/patient-list"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/admin/patient-list">
                        <a
                          className="nav-link active"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/patient.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/patient-active.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span> Resident List</span>
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "/admin/patient-health"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/admin/patient-health">
                        <a
                          className="nav-link active d-flex gap-1"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/patient.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/patient-active.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span> Resident Health 
                          Summary</span>
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "/invoice"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/invoice">
                        <a
                          className="nav-link active"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/invoice.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/invoice-active.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span> Billing & Invoice</span>
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "/staff-details"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/staff-details">
                        <a
                          className="nav-link active"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/staff.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/staff-active.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span> Staff Management</span>
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "/admin/medication"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/admin/medication">
                        <a
                          className="nav-link active d-flex gap-1"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/medicine-stock.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/medicine-stock-active.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span> Medication Stock
                          Audit</span>
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "/admin/doctor-medication-management"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/admin/doctor-medication-management">
                        <a
                          className="nav-link active d-flex gap-1"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/medicine-stock.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/medicine-stock-active.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span> Medication Management</span>
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "/family/health-monitoring"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/family/health-monitoring">
                        <a
                          className="nav-link active d-flex gap-1"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/health-monitoring-2.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/hm-active.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span>  Health Monitoring</span>
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "/messagechat"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/messagechat">
                        <a
                          className="nav-link active d-flex gap-1"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/messages.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/medicine-stock-active.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span> Communication Log</span>
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "/family/activity-tracker"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/family/activity-tracker">
                        <a
                          className="nav-link active d-flex gap-1"
                          aria-current="page"
                          href="family/activity-tracker"
                        >
                          <img
                            src="/images/page-search-lines-2.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/at-active.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span> Activity tracker</span>
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "#"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="#">
                        <a
                          className="nav-link active d-flex gap-1"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/file-text-2.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/at-active.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span> Incident Reports</span>
                        </a>
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        window.location.pathname === "#"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="#">
                        <a
                          className="nav-link active d-flex gap-1"
                          aria-current="page"
                          href="#"
                        >
                          <img
                            src="/images/home-2.svg"
                            alt=""
                            className="white-icon"
                          />
                          <img
                            src="/images/home.svg"
                            alt=""
                            className="color-icon"
                          />
                          <span> Reports & Analytics</span>
                        </a>
                      </Link>
                    </li>
                    
                    <li className="nav-item logout-button">
                      <a className="nav-link" href="#">
                        <img
                          src="/images/logout.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="" alt="" className="color-icon" />
                        <span> Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <div className="aside-toggle">
              <i className="fa-solid fa-angle-left"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
