import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./dashboard.css";

export default function FamilyDashboard() {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;
  return (
    <>
      <div className="dashboard">
        <div className="dashboard-two">
          <div className="top-bar">
            <div className="top-bar-inner">
              <div className="mobile-menu d-lg-none">
                <div className="aside-bar">
                  <div className="aside-bar-menu">
                    <nav className="navbar navbar-expand-lg">
                      <div className="">
                        <button
                          className="navbar-toggler"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <i className="fa-light fa-bars"></i>
                        </button>

                        <div
                          className="collapse navbar-collapse"
                          id="navbarSupportedContent"
                        >
                          <div className="logo">
                            <Link className="navbar-brand" href="#">
                              <img
                                src="/images/endwelllcare-logo.png"
                                alt="Logo"
                              />
                            </Link>
                          </div>
                          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item active">
                              <Link
                                className="nav-link active"
                                aria-current="page"
                                to="/family/home"
                              >
                                <img
                                  src="/images/home-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img
                                  src="/images/home.svg"
                                  alt=""
                                  className="color-icon"
                                />
                                Home
                              </Link>
                            </li>
                            <li className="nav-item ">
                              <Link
                                className="nav-link"
                                to="/family/health-monitoring"
                              >
                                <img
                                  src="/images/health-monitoring-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span> Health Monitoring</span>
                              </Link>
                            </li>
                            <li className="nav-item ">
                              <Link
                                className="nav-link d-flex gap-1"
                                to="/family/medication-management"
                              >
                                <div>
                                  <img
                                    src="/images/medicine-stock.svg"
                                    alt=""
                                    className="white-icon"
                                  />
                                  <img src="/images/medicine-stock-active.svg" alt="" className="color-icon" />
                                </div>
                                <span className="d-block">
                                  Medication Management
                                </span>
                              </Link>
                            </li>
                            <li className="nav-item ">
                              <Link
                                className="nav-link"
                                to="/family/activity-tracker"
                              >
                                <img
                                  src="/images/page-search-lines-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span> Activity tracker</span>
                              </Link>
                            </li>
                            <li className="nav-item ">
                              <Link className="nav-link" href="#">
                                <img
                                  src="/images/file-text-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span>Incident Reports</span>
                              </Link>
                            </li>
                            <li className="nav-item ">
                              <Link className="nav-link" href="#">
                                <img
                                  src="/images/book-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span>Communication Log</span>
                              </Link>
                            </li>
                            <li className="nav-item ">
                              <Link className="nav-link" href="#">
                                <img
                                  src="/images/key-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span>Care Plan Access</span>
                              </Link>
                            </li>
                            <li className="nav-item ">
                              <Link className="nav-link" href="#">
                                <img
                                  src="/images/trending-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span> Reports & Analytics</span>
                              </Link>
                            </li>
                            <li className="nav-item ">
                              <Link className="nav-link" href="#">
                                <img
                                  src="/images/receipt-bill-2.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span> Billing</span>
                              </Link>
                            </li>
                            <li className="nav-item logout-button">
                              <Link className="nav-link" href="#">
                                <img
                                  src="/images/logout.svg"
                                  alt=""
                                  className="white-icon"
                                />
                                <img src="" alt="" className="color-icon" />
                                <span> Logout</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="dummy-nav me-3">
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                  <div className="container-fluid">
                    <div
                      className="collapse navbar-collapse"
                      id="navbarNavDropdown"
                    >
                      <ul className="navbar-nav">
                        <li className="nav-item dropdown px-2">
                          <Link
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            All Links
                          </Link>
                          <ul className="dropdown-menu">
                            <div className="mygird-ul">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/registration"
                                >
                                  new registration
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/carehome"
                                >
                                  carehome
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/patient-list"
                                >
                                  patient list
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/patient-health"
                                >
                                  patient health
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/medication-management"
                                >
                                  medication management
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/activity-tracker"
                                >
                                  activity tracker
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/diet-nutrition"
                                >
                                  diet nutrition
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/health-monitoring"
                                >
                                  health monitoring
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/doctor-medication-management"
                                >
                                  doctor medication management
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/messagechat"
                                >
                                  messagechat
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/nurse/resident-health-data"
                                >
                                  nurse resident healthdata
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/nurse/resident-health-data-view"
                                >
                                  nurse resident healthdataview
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/nurse/notification"
                                >
                                  nurse notification
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/nurse/medications"
                                >
                                  nurse medications
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/admin/medication"
                                >
                                  medication
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/staff-details"
                                >
                                  staff-details
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/invoice">
                                  invoice
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/doctor">
                                  doctor
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/home"
                                >
                                  family home
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/login"
                                >
                                  family login
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/signup"
                                >
                                  family signup
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/family/otp"
                                >
                                  family otp
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/forgot-password"
                                >
                                  forgot-password
                                </Link>
                              </li>
                            </div>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="top-bar-search">
                <div className="sarch-bar position-relative">
                  <input type="search" placeholder="Search " />
                  <img src="/images/search-normal.png" />
                </div>
                <div className="bell-icon">
                  <i className="fa-regular fa-bell"></i>
                  <span></span>
                </div>
                <div className="login-profile">
                  <div className="Profile-image">
                    <img src="/images/profile-image.jpeg" alt="" />
                  </div>
                  <div className="profile-details">
                    <p>Ashok</p>
                    <span>Son</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tab-menu">
      <div className="aside-bar">
        <div className="aside-bar-menu">
          <nav className="navbar navbar-expand-lg">
            <div className="">
              <div className="logo">
                <Link className="navbar-brand" href="#">
                  <img src="/images/endwelllcare-logo.png" alt="" />
                </Link>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className={`nav-item ${isActive("/family/home") ? "active" : ""}`}>
                    <Link className="nav-link" to="/family/home">
                      <img
                        src="/images/home-2.svg"
                        alt=""
                        className="white-icon"
                      />
                      <img
                        src="/images/home.svg"
                        alt=""
                        className="color-icon"
                      />
                       <span> Dashboard</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("/family/health-monitoring") ? "active" : ""}`}>
                    <Link className="nav-link" to="/family/health-monitoring">
                      <img
                        src="/images/health-monitoring-2.svg"
                        alt=""
                        className="white-icon"
                      />
                      <img src="/images/hm-active.svg" alt="" className="color-icon" />
                      <span> Health Monitoring</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("/family/medication-management") ? "active" : ""}`}>
                    <Link className="nav-link d-flex gap-1" to="/family/medication-management">
                      <div>
                        <img
                          src="/images/medicine-stock.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="/images/medicine-stock-active.svg" alt="" className="color-icon" />
                      </div>
                      <span className="d-block">Medication Management</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("/family/diet-nutrition") ? "active" : ""}`}>
                    <Link className="nav-link d-flex gap-1" to="/family/diet-nutrition">
                      <div>
                        <img
                          src="/images/diet-icon.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="/images/diet-active.svg" alt="" className="color-icon" />
                      </div>
                      <span className="d-block">Diet & Nutrition</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("/family/activity-tracker") ? "active" : ""}`}>
                    <Link className="nav-link d-flex gap-1" to="/family/activity-tracker">
                      <div>
                        <img
                          src="/images/page-search-lines-2.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="/images/at-active.svg" alt="" className="color-icon" />
                      </div>
                      <span className="d-block">Activity tracker</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("/messagechat") ? "active" : ""}`}>
                    <Link className="nav-link d-flex gap-1" to="/messagechat">
                      <div>
                        <img
                          src="/images/messages.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="/images/book-active.svg" alt="" className="color-icon" />
                      </div>
                      <span className="d-block">Communication Logs</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("#") ? "active" : ""}`}>
                    <Link className="nav-link d-flex gap-1" to="#">
                      <div>
                        <img
                          src="/images/file-text-2.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="/images/at-active.svg" alt="" className="color-icon" />
                      </div>
                      <span className="d-block">Incident Reports</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("#") ? "active" : ""}`}>
                    <Link className="nav-link" to="#">
                      <img
                        src="/images/home-2.svg"
                        alt=""
                        className="white-icon"
                      />
                      <img
                        src="/images/home.svg"
                        alt=""
                        className="color-icon"
                      />
                      <span> Personal Care</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("#") ? "active" : ""}`}>
                    <Link className="nav-link d-flex gap-1" to="#">
                      <div>
                        <img
                          src="/images/home-2.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="/images/home.svg" alt="" className="color-icon" />
                      </div>
                      <span className="d-block">Social & Emotional Wellbeing</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("#") ? "active" : ""}`}>
                    <Link className="nav-link d-flex gap-1" to="#">
                      <div>
                        <img
                          src="/images/file-text-2.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="/images/at-active.svg" alt="" className="color-icon" />
                      </div>
                      <span className="d-block">Incident Reports</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("#") ? "active" : ""}`}>
                    <Link className="nav-link d-flex gap-1" to="#">
                      <div>
                        <img
                          src="/images/home-2.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="/images/home.svg" alt="" className="color-icon" />
                      </div>
                      <span className="d-block">Reports & Analytics</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("#") ? "active" : ""}`}>
                    <Link className="nav-link d-flex gap-1" to="#">
                      <div>
                        <img
                          src="/images/home-2.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="/images/home.svg" alt="" className="color-icon" />
                      </div>
                      <span className="d-block">Safety & Mobility</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActive("#") ? "active" : ""}`}>
                    <Link className="nav-link d-flex gap-1" to="#">
                      <div>
                        <img
                          src="/images/home-2.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="/images/home.svg" alt="" className="color-icon" />
                      </div>
                      <span className="d-block">Care Plan Access</span>
                    </Link>
                  </li>
                  <li className="nav-item logout-button">
                      <Link className="nav-link" to="/family/login">
                        <img
                          src="/images/logout.svg"
                          alt=""
                          className="white-icon"
                        />
                        <img src="" alt="" className="color-icon" />
                        <span> Logout</span>
                      </Link>
                    </li>
                  {/* Add more links similarly */}
                </ul>
              </div>
            </div>
          </nav>
          <div className="aside-toggle">
            <i className="fa-solid fa-angle-left"></i>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
