import logo from './logo.svg';
import './App.css';
import Login from './components/Admin/Login/Login';
import Admin from './components/Admin/Login/Admin';
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Registration from './components/Admin/Registration/registration';
import Doctor from './components/Doctor/Doctor/Doctor';
import FamilyLogin from './components/Family/Login/Login';
import FamilySignup from './components/Family/Signup/Signup';
import FamilyOTP from './components/Family/OTP/Otp';
import FamilyHome from './components/Family/FamilyHome/FamilyHome';
import CareHome from './components/Admin/CareAdmin/CareHome/CareHome';
import Invoice from './components/Admin/CareAdmin/Invoice/Invoice';
import Forgot from './components/Admin/ForgotPassword/Forgot'
import StaffDetails from './components/Admin/Staff/StaffDetails';
import PatientList from './components/Admin/PatientList/PatientList';
import PatientHealth from './components/Admin/PatientHealthSD/PatientHealth';
import MedicationManage from './components/Family/MedicationManagement/MedicationManage';
import ActivityTracker from './components/Family/ActivityTracker/ActivityTracker';
import DietNutrition from './components/Family/DietNutrition/DietNutrition';
import HealthMonitoring from './components/Family/HealthMonitoring/HealthMonitoring';
import DoctorMedicationManagement from './components/Doctor/DoctorMedicationManagement/DoctorMedicationManagement';
import MessageChat from './components/Admin/ConversationsChat/MessageChat';
import ResidentHealthData from './components/Nurse/ResidentHealthData/ResidentHealthData';
import ResidentHealthDataView from './components/Nurse/ResidentHealthDataView/ResidentHealthDataView';
import Notification from './components/Nurse/Notifications/Notifications';
import Medications from './components/Nurse/Medications/Medications';
import Medication from './components/Admin/Medication/Medication';
function App() {
  return (
    <>
    {/* <Login/> */}
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/family/login" element={<FamilyLogin />} />
        <Route path="/family/signup" element={<FamilySignup />} />
        <Route path="/family/otp" element={<FamilyOTP />} />
        <Route path="/family/home" element={<FamilyHome />} />
        <Route path="family/medication-management" element={<MedicationManage />} />
        <Route path="family/activity-tracker" element={<ActivityTracker />} />
        <Route path="family/diet-nutrition" element={<DietNutrition />} />
        <Route path="family/health-monitoring" element={<HealthMonitoring />} />
        <Route path="messagechat" element={<MessageChat />} />
        <Route path="/doctor" element={<Doctor />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/forgot-password" element={<Forgot/>} />
        <Route path="/staff-details" element={<StaffDetails/>} />

        <Route path="/admin" element={<Admin />} >
          <Route path="carehome" element={<CareHome />} />
          <Route path="registration" element={<Registration />} />
          <Route path="patient-list" element={<PatientList />} />
          <Route path="patient-health" element={<PatientHealth />} />
          <Route path="doctor-medication-management" element={<DoctorMedicationManagement />} />
          <Route path="nurse/resident-health-data" element={<ResidentHealthData />} />
          <Route path="nurse/resident-health-data-view" element={<ResidentHealthDataView />} />
          <Route path="nurse/notification" element={<Notification />} />
          <Route path="nurse/medications" element={<Medications />} />
          <Route path="medication" element={<Medication />} />
        </Route>
      </Routes>
    </Router>
    </>
    
  );
}

export default App;
